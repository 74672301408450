import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Сервіс по ремонту ПК
			</title>
			<meta name={"description"} content={"Ми в Комп'ютерний Сервіс цінуємо прозорість та задоволеність клієнтів. "} />
			<meta property={"og:title"} content={"FAQ | Сервіс по ремонту ПК"} />
			<meta property={"og:description"} content={"Ми в Комп'ютерний Сервіс цінуємо прозорість та задоволеність клієнтів. "} />
			<meta property={"og:image"} content={"https://risonda.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://risonda.live/img/317-3179993.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://risonda.live/img/317-3179993.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://risonda.live/img/317-3179993.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://risonda.live/img/317-3179993.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://risonda.live/img/317-3179993.png"} />
			<meta name={"msapplication-TileImage"} content={"https://risonda.live/img/317-3179993.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-8">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Поширені запитання
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Ми в Комп'ютерний Сервіс цінуємо прозорість та задоволеність клієнтів. Ось відповіді на деякі з найпоширеніших запитань, які ми отримуємо, забезпечуючи ясність і впевненість у наших послугах.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						З якими проблемами з ПК може впоратися Комп'ютерний Сервіс?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ми вирішуємо широкий спектр проблем з ПК, включаючи апаратні несправності, програмні збої, видалення вірусів, оптимізацію системи та багато іншого.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Скільки часу займає типовий ремонт?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Час ремонту залежить від складності проблеми. Ми прагнемо до швидкого виконання робіт, забезпечуючи при цьому якісний сервіс. Ви отримаєте орієнтовний графік після первинної оцінки.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи збережуться мої дані під час ремонту?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Безумовно. Ми надаємо пріоритет цілісності та конфіденційності даних. Якщо необхідна передача або резервне копіювання даних, ми зробимо це з максимальною обережністю.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи надаєте ви гарантію на свої послуги з ремонту?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Так, ми надаємо гарантію на певні послуги та деталі. Це залежить від характеру ремонту та використаних компонентів.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи може Комп'ютерний Сервіс оновити мій старий ПК?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ми пропонуємо послуги з модернізації для підвищення продуктивності вашого ПК. Оновлення залежить від сумісності вашого комп'ютера та бажаного результату.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Що робити, якщо мій комп'ютер заражений вірусом?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Уникайте використання комп'ютера, щоб запобігти подальшому пошкодженню. Принесіть його до нас для ретельної перевірки та видалення вірусів, щоб забезпечити чистоту та безпеку вашої системи.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});